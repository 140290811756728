/* @import url('bootstrap/dist/css/bootstrap.min.css'); */
.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

nav.navbar.bg-primary {
  background: linear-gradient(87deg, #63160a, #a11e19);
}

a.nav-link {
  color: white;
  padding: 10px 30px !important;
}

.ant-picker {
  background: transparent;
  border: none;
  width: 100%;
  height: 100%;
  font-family: Armstrong;
}

.ant-picker-input>input {
  color: white !important;
  font-family: Armstrong;
}

.ant-picker-calendar {
  font-family: Armstrong;
}

.mothersday-banner {
  background: #f5f5f5;
  background-size: cover;
  width: 100%;
  height: 480px;
  background-position: bottom;
}

footer.custom-footer {
  padding: 2rem !important;
  background: #991e17;
  box-shadow: 4px 7px 11px 4px #471a1887;
}

.bg-grey.register-section {
  background: #f5f5f5 !important;
}

.inner-footer span.text-muted {
  color: #f5f5f5 !important;
  font-size: 20px !important;
}

.img-section img {
  width: 100%;
  min-height: 650px;
  height: 100%;
  object-fit: cover;
}

.row {
  --bs-gutter-x: 0rem !important;
}

.form-oneline.row .form-pad {
  padding: 10px 20px;
}

.btn-custom {
  text-align: center;

}

.btn-custom button {
  width: 50% !important;
  background: #8d1b14 !important;
  padding: 15px 20px;
  border-radius: 50px;
  margin-top: 30px !important;
  border: none;
}

.form-control {
  padding: 15px;
  background: transparent;
  border-radius: 50px;
  border: 1px solid #494949;
  font-size: 13px;
  font-weight: 500;
  color: #494949;
  width: 85%;
}

.btn-back button {
  width: 50% !important;
  background: #ffffff !important;
  padding: 12px 20px;
  border-radius: 50px;
  margin-top: 30px !important;
  border: 1px solid #8d1b14;
  color: #8d1b14;
}

.btn-back button:hover {
  background: #c5141e !important;
  border: none !important;
  color: #ffffff !important;
  transition: 0.5s ease;
}

.btn-custom button:hover {
  background: #64170a !important;
  color: #ffffff !important;
  transition: 0.5s ease;
}

button#btnscroll {
  background: linear-gradient(167deg, #c79b31, #fce883);
  border: none;
  padding: 10px 20px;
  width: 190px;
  color: #ffffff;
  border-radius: 30px;
  font-weight: 600;
  font-size: 20px;
}

button#btnscroll span svg {
  width: 20px;
  height: 20px;
  fill: white;
  margin-left: 5px;
}

button#btnscroll span svg path {
  fill: white;
}

span.text-danger,
p.text-danger {
  float: right;
  font-size: 17px;
  margin-top: 8px;
}

.align-center {
  align-items: center;
  justify-content: center !important;
}

a.navbar-brand img {
  width: 130px;
}

img.desktop-banner {
  height: 100%;
}

/* .btns-banner {
  position: absolute;
  top: 20px;
} */
.btns-banner a img {
  width: 110px;
  padding: 10px;
  margin: 10px 8px;
  background: white;
  border-radius: 5px;
}

.btns-banner a img:hover {
  background: whitesmoke;
}

.btns-banner {
  background: linear-gradient(90deg, #640b0a, #660d0a);
}

.bg-grey.register-section {
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

div#form {
  padding: 30px;
  background: white;
  border-radius: 10px;
  min-width: 650px;
}

.reg-form-back {
  min-width: 75vw !important;
}

.preview-page p strong {
  color: #c5141e;
  font-weight: 500;
}

.preview-page p {
  color: #141414 !important;
  font-size: 22px;
  font-weight: 500 !important;
}

p.center {
  text-align: center;
  font-size: 22px;
}

h5.text-center.mother-head {
  font-size: 2.5rem;
  color: #c5141e;
}

label.form-label {
  color: #c5141e !important;
  font-weight: 500 !important;
  font-size: 20px;
}

img.mobile-banner {
  display: none;
}

img.desktop-banner {
  display: block;
}

a.symbol-btn {
  cursor: copy;
}

svg.mobile-banner {
  display: none;
}

svg.desktop-banner {
  display: block;
}

@media (max-width:600px) {
  svg.mobile-banner {
    display: block !important;
  }

  svg.desktop-banner {
    display: none !important;
  }

}

@media (max-width: 767px) {
  .modal-body .tc-popup {
    height: 400px;
    overflow-y: scroll;
  }

  .modal-body .tc-quiz-popup {
    height: 600px;
    /* width: 450px; */
    max-width: 100%; 
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .tc-quiz-popup {
    padding: 0px 2%;
  }

  .tc-quiz-popup ol li {
    padding: 1px;
    font-size: 12px;
  }

  span.text-engrave-note {
    font-size: 18px !important;
    margin-left: 12px;
    margin-right: 12px;
  }

  .img-preview img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  img.mobile-banner {
    display: block;
  }

  img.desktop-banner {
    display: none;
  }

  /* .btns-banner {
  position: absolute;
  top: 0px;
} */
  .btns-banner a img {
    width: 80px;
    position: relative;
    left: 25%;
    bottom: 210px;
    padding: 10px;
    margin: 10px 8px;
    background: white;
    border-radius: 5px;
  }

  div#form {
    padding: 20px !important;
    background: white;
    border-radius: 10px;
    min-width: 360px;
    margin-top: 0px;
  }

  .form-oneline.row .form-pad {
    padding: 10px 20px;
  }

  .reg-form-back form {
    min-width: 300px;
  }

  .form-oneline.row .form-pad {
    padding: 10px 15px;
  }

  .form-control {
    padding: 15px;
    background: transparent;
    border-radius: 50px;
    border: 1px solid #494949;
    font-size: 12px;
    font-weight: 500;
    color: #494949;
    min-width: 200px;
  }

  .btn-custom button {
    width: 75% !important;
    background: #8d1b14 !important;
    padding: 15px 20px;
    border-radius: 50px;
    margin-top: 30px !important;
    border: none;
  }

  .btn-back button {
    width: 75% !important;
    background: #ffffff !important;
    padding: 12px 20px;
    border-radius: 50px;
    margin-top: 30px !important;
    border: 1px solid #8d1b14;
    color: #8d1b14;
  }

  img.ele-1 {
    display: none !important;
  }

  img.ele-2 {
    display: none !important;
  }

  img.ele-3 {
    display: none !important;
  }

  img.ele-4 {
    display: none !important;
  }

  .reg-form-back {
    min-width: 300px !important;
  }
}

/* 
@media (max-width: 600px) {
  .btns-banner a img {
    width: 80px;
    position: relative;
    left: 30vw;
    bottom: 30vh;
    padding: 10px;
    margin: 10px 8px;
    background: white;
    border-radius: 5px;
  }

}
@media (max-width: 420px) {
  .btns-banner a img {
    width: 80px;
    position: relative;
    left: 26%;
    bottom: 196px;
    padding: 10px;
    margin: 10px 8px;
    background: white;
    border-radius: 5px;
  }
  }


@media (max-width: 360px) {

  .btns-banner a img {
    width: 70px;
    position: relative;
    left: 22%;
    bottom: 184px;
    padding: 10px;
    margin: 10px 8px;
    background: white;
    border-radius: 5px;
}
}
@media (max-width: 340px) {

  .btns-banner a img {
    width: 70px;
    position: relative;
    left: 20%;
    bottom: 167px;
    padding: 10px;
    margin: 10px 8px;
    background: white;
    border-radius: 5px;
}
} */
::placeholder {
  opacity: 0.5 !important;
}


.btn-bg {
  background: #640b0a !important;
  padding-top: 30px;
}

.btns-banner h3 {
  font-size: 22px;
  color: white;
}

.btns-banner {
  text-align: center;
  width: 35% !important;
  position: absolute;
  background: none;
  top: 46vh;
}

button#rcc-confirm-button {
  background: linear-gradient(45deg, #f4dd78, #d5ae47) !important;
  border: 0px;
  padding: 20px;
  border-radius: 20px !important;
  box-shadow: none;
  color: rgb(255 255 255);
  cursor: pointer;
  flex: 0 0 auto;
  padding: 10px 20px !important;
  margin: 15px;
  font-size: 13px;
  font-weight: 600;
}

.CookieConsent {
  width: 100% !important;
  padding: 20px !important;
  background: #000000bf !important;
  justify-content: center !important;
  align-items: center !important;
}

h2.fw-bold.mb-2.text-uppercase {
  font-size: 3rem;
}

p.text-cenetr {
  font-size: 22px;
}

.preview-page h4 {
  font-size: 2.4rem;
}

button {
  font-size: 21px !important;
}

p {
  font-size: 22px;
}

.art-work-particle {
  position: absolute;
  top: 0px;
  right: 0px;
}

.header-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  z-index: 10;
}

.header-btn img {
  width: 90%;
  padding: 0px 20px !important;
}

.content-text {
  text-align: center;
  color: white;
  color: #FFF;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 10px;
}

.img-element {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-element img {
  width: 200px;
}

.img-element-share {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-element-share img {
  width: 200px;
}

.layer-element {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-element img {
  position: absolute;
  bottom: 38%;
}

.iPadPro {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iPadPro img {
  height: 490px;
  width: 390px;
}


.img-element-share img {
  position: absolute;
  bottom: 57%;
}

button.start-btn {
  width: 100%;
  border: none;
  color: #000;
  font-style: normal;
  font-weight: 400;
  padding: 10px 15px;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: 100% 100%;
}

.pad-tb-30 {
  padding-top: 60px;
  padding-bottom: 100px;
}

.pad-tb-10 {
  padding-top: 10px;
  padding-bottom: 20px;
}

.pad-tb-20 {
  padding-top: 30px;
  padding-bottom: 20px;
}

.mission-section {
  padding: 10px;
  z-index: 100;
}

.mission-section {
  padding: 10px;
  position: relative;
  z-index: 1000;
}

.count-header h3 {
  color: #EAE255;
  font-family: Armstrong;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.count-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c-level-up {
  display: flex;
  align-items: center;
  justify-content: center;
}

h4.c-text-inside-circle {
  position: absolute;
  margin-bottom: 0px;
  text-align: center;
  color: white;
  font-size: 9px;
  right: 20px;
  top: 30px;
}

.box {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: linear-gradient(61deg, #0000002b 40%, #00800047);
  animation: rotate 5s linear infinite;
  box-shadow: 1px 1px 15px 5px #134f2e;
}

.box:before,
.box:after {
  content: " ";
  position: absolute;
  inset: 16px;
  border-radius: inherit;
  box-shadow: 2px 1px 10px 5px #1987543d;
}

.box img {
  width: 100%;
}


@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.c-outer-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.mar-top-50 {
  margin-top: 35px;
}

.mar-top-10 {
  margin-top: 10px;
}

.c-content-heading h2 {
  color: #FFF;
  text-align: center;
  font-family: 'Armstrong';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.c-content-heading h4 {
  color: #FFF;
  text-align: center;
  font-family: 'Armstrong';
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.c-content-question {
  text-align: center;
  color: #FFF;
  text-align: center;
  font-family: Armstrong;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.e-answer {
  width: 100%;
  height: 100%;
  padding: 10px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  margin-top: 10px;
}

.e-answer p {
  color: #FFF;
  font-family: Armstrong;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  margin: 0px;
}

.e-result {
  width: 100%;
  height: 100%;
  padding: 10px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: 10px;
  margin-top: 0px;
}

.e-result p {
  color: #FFF;
  font-family: Armstrong;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  margin: 0px;
}

.e-share {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: 10px;
}

.e-share p {
  color: #FFF;
  font-family: Armstrong;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  margin: 0px;
}

.e-status {
  width: 100%;
  height: 100%;
  padding: 10px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: 10px;
  margin-top: 10px;
}

.e-status p {
  color: #FFF;
  font-family: Armstrong;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  margin: 0px;
}

.e-rank {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: 10px;
}

.e-rank p {
  color: #FFF;
  font-family: Armstrong;
  font-size: 25px !important;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  margin: 0px;
}

.brain {
  text-align: center;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

/* .brain img {
  position: absolute;
  top: 15%;
  opacity: 0.2;
  width: 150px;
} */
.img-element.e-graph img {
  bottom: 25%;
  width: 150px;
}

.img-element-share.e-graph-share img {
  bottom: 45%;
  width: 150px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.bg-grey {
  max-width: 600px;
  margin: 0px auto;
}

.start-btn.btn:hover {
  background-color: transparent !important;
  border-color: transparent !important;
}

.start-btn.btn:disabled {
  background-color: transparent !important;
  border-color: transparent !important;
  color: black;
  opacity: 0.3;
}

.pad-t-30 {
  padding-top: 30px;
}

.answer {
  margin: 0px 20px;
}

.social-media-share {
  margin: 25px;
}

.form-control {
  min-height: 60px !important;
}

select.form-select {
  min-height: 60px !important;
}

::placeholder {
  color: white !important;
}

.input-class::placeholder {
  color: white !important;
}

.share-para p {
  text-align: center;
  font-size: 16px !important;
}

.share-title h5 {
  color: white !important;
}

.btn-mission h3 {
  font-family: Armstrong-extrabold;
  margin-bottom: -5px;
  margin-left: 12px;
  font-size: 26px;
  padding: 0px;
  font-weight: 800 !important;
  text-align: center;
  background: linear-gradient(to right, #FFF 20%, #f7fffb 40%, #f6f6f6 60%, #FFF 80%);
  background-size: 250% auto;
  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: white;
  text-shadow: 1px 1px 5px #09b666;
}

img.brain-center {
  position: absolute;
  top: 168px;
  width: 150px;
  z-index: 1000;
}

img.brain-center-result {
  position: absolute;
  top: 107px;
  width: 150px;
  z-index: 1000;
}

.btn-mission {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 5px;
  width: 320px;
  border: 8px solid #0000;
  border-radius: 50px;
  background: linear-gradient(#0B3021, #008A57, #000000) padding-box, linear-gradient(var(--angle), rgb(90 90 90 / 80%) 0%, rgba(255, 255, 255, 1) 50%, rgb(152 160 167) 99%, rgba(255, 255, 255, 1) 100%) border-box;
  animation: 8s rotatenew linear infinite;
  box-shadow: inset 0 0 4px 0 #00d762;
}

@keyframes rotatenew {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}
/* .header-btn img {
  width: 100%;
  padding: 0px 20px;
  transform: rotate(4deg);
} */
img.grow-svg {
  width: 90px !important;
  padding: 0px !important;
}
.quiz-start {
  position: relative;
  z-index: 100;
}
.img-element-main img {
  width: 100%;
}


/* .content {
  position: relative;
} */

.content img {
  position: absolute;
  transform: translate(-50%, -50%);

}


.content img:nth-child(1) {
  color: transparent;
  /* -webkit-text-stroke: 2px #afda05; */
}

.content img:nth-child(2) {
  color: #e4f403;
  animation: animate 5s ease-in-out infinite;
  opacity: 1 !important;
}

@keyframes animate 
{
  0%, 100% 
  {
    clip-path: polygon(0% 45%, 15% 44%, 32% 50%, 54% 60%, 70% 61%, 84% 59%, 100% 52%, 100% 100%, 0% 100%)
  }
  50%
  {
    clip-path: polygon(0 60%, 16% 65%, 34% 66%, 51% 62%, 67% 50%, 84% 45%, 100% 40%, 100% 100%, 0% 100%)
  }
}
.content-text h3 , .content-text p {
  font-style: italic !important;
  font-family: 'Armstrong-extrabold';
}

select option{
  color:black !important;
}
.content-text a {
  color: white !important;
  padding: 0px 5px;
}
.quiz-start a {
  color: white !important;
  padding: 0px 5px;
}
.img-element-main {
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-element-main img {
  width: 80%;
}