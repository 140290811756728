    .social{
        width: 65px;
        height: 65px;
        border-radius: 50%;
        background-color: transparent;
        border: none;
    }

    .socail:hover{
        background-color: white !important;
    }

    .facebook {
        width: 55px;
        height: 55px;
        border-radius: 20%;
        border: none;
        color: white;
        background-color: #3b5998;
    }

    .fa{
        margin-top: 10px;
        font-size: 45px !important;
    }

   
    .toastBar{
        display: none;
    }
    @media screen and (max-width: 524px) {
        .toastBar{
            transform: translateX(-50%);
            background: #04040436;
            color: #fff;
            padding: 8px 16px;
            border-radius: 50px;
            opacity: 1;
            pointer-events: none; /* This prevents clicks on the toast container */
            transition: opacity 0.3s ease-in-out;       
    }
      }