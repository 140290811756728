footer.custom-footer {
    background: #034638 !important;
    box-shadow: 0px -4px 6px 2px #1c4f449e;
}
.bg-gradient-green {
    background-image: url("/public/BK-mobile.png");
    min-height: 100vh !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}
/* .bg-gradient-green:before {
    position: absolute;
    top: 0;
    content: '';
    background: linear-gradient(185.31deg, #00ff71 4.26%, #035928 26.61%) !important;
    background-size: cover !important;
    width: 100%;
    height: 100%;
    opacity: 0.1;
} */
.btn-bg {
    background: #003d19 !important;
}

h5.text-center.mother-head {
    color: #034638 !important;
}
label.form-label {
    color: #034638 !important;
}
.btn-custom button {
    width: 40% !important;
    background: #034638 !important;
}
.btn-custom button:hover {
    background: #002820 !important;
}
.preview-page p strong {
    color: #034638 !important;
}
.btn-back button {
    width: 40% !important;
    border: 1px solid #034638;
    color: #034638;
}
.btn-back button:hover {
    background: #25816e !important;
}
button.btn.btn-thank {
    background: #034638;
}
button.btn.btn-thank:hover {
    background: #001e18 !important;
}
.reg-form-back {
    width: 60vw !important;
}
.form-oneline.row .form-pad {
    padding: 5px 20px !important;
}